var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yuYueProductDetails" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _vm._m(0),
        _c(
          "el-form",
          {
            staticStyle: { "padding-left": "100px" },
            attrs: { "label-position": "right" },
          },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "停车场名称:" } }, [
                      _c("span", { staticClass: "spanA" }, [
                        _vm._v(_vm._s(_vm.formInline.slaveRelation)),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "商品状态:" } }, [
                      _c("span", { staticClass: "spanA" }, [
                        _vm._v(_vm._s(_vm.formInline.productState)),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "预约日期：", prop: "searchDateS" } },
              [
                _c("span", { staticClass: "spanA" }, [
                  _vm._v(
                    _vm._s(_vm.formInline.searchDateS[0]) +
                      "至" +
                      _vm._s(_vm.formInline.searchDateS[1])
                  ),
                ]),
              ]
            ),
            _c(
              "el-form-item",
              { attrs: { label: "预约时间：", prop: "searchDateS" } },
              [
                _c("span", { staticClass: "spanA" }, [
                  _vm._v(
                    _vm._s(_vm.formInline.startTime) +
                      " - " +
                      _vm._s(_vm.formInline.endTime)
                  ),
                ]),
              ]
            ),
            _c(
              "el-form-item",
              { attrs: { label: "泊位保留时长:", prop: "timer" } },
              [
                _c("span", { staticClass: "spanA" }, [
                  _vm._v(_vm._s(_vm.formInline.timer) + " 分钟"),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._m(1),
        _c(
          "el-form",
          {
            staticStyle: { "padding-left": "100px" },
            attrs: { "label-position": "right" },
          },
          [
            _c(
              "el-form-item",
              {
                staticClass: "parknum",
                attrs: {
                  label: "预约泊位数量:",
                  prop: "appointmentBerthNumber",
                },
              },
              [
                _c("span", { staticClass: "spanA" }, [
                  _vm._v(_vm._s(_vm.formInline.appointmentBerthNumber) + "个"),
                ]),
              ]
            ),
            _c("el-form-item", { attrs: { label: "押金:", prop: "yajin" } }, [
              _c("span", { staticClass: "spanA" }, [
                _vm._v(_vm._s(_vm.formInline.yajin)),
              ]),
              _vm._v(" "),
              _vm.formInline.yajin != "免押金"
                ? _c("span", [_vm._v("元")])
                : _vm._e(),
            ]),
            _c(
              "el-form-item",
              { attrs: { label: "用户可提前", prop: "tiqian" } },
              [
                _c(
                  "span",
                  {
                    staticClass: "spanA",
                    staticStyle: { "margin-left": "-12px" },
                  },
                  [
                    _c("span", { staticStyle: { color: "blue" } }, [
                      _vm._v(_vm._s(_vm.formInline.tiqian) + " "),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.formInline.ytimeType == 0
                            ? "分钟预约车位"
                            : "天预约车位"
                        )
                      ),
                    ]),
                  ]
                ),
              ]
            ),
            _vm.formInline.yajin != "免押金"
              ? _c(
                  "el-form-item",
                  { attrs: { label: "用户可提前", prop: "quxiao" } },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "spanA",
                        staticStyle: { "margin-left": "-12px" },
                      },
                      [
                        _c("span", { staticStyle: { color: "blue" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.formInline.quxiao == -1
                                ? ""
                                : _vm.formInline.quxiao
                            )
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.formInline.quxiao == -1
                                ? ":不可取消"
                                : "分钟之前自主取消预约"
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "el-form-item",
              { attrs: { label: "预约说明:", prop: "textarea" } },
              [
                _c("span", { staticClass: "spanA" }, [
                  _vm._v(_vm._s(_vm.formInline.textarea)),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              margin: "0 auto",
              width: "250px",
              "margin-bottom": "20px",
            },
          },
          [
            _c(
              "el-button",
              { attrs: { type: "button" }, on: { click: _vm.goback } },
              [_vm._v("返回")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "title" }, [
      _c("span", { staticClass: "title_icon" }),
      _vm._v(" 基础信息 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "title" }, [
      _c("span", { staticClass: "title_icon" }),
      _vm._v(" 销售信息 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }